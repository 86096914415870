import UserContext from "../stores/user-context";
import { useContext, useEffect, useCallback, useState } from "react";

export const userStatus = () => {
  const userCtx = useContext(UserContext);
  const [isAffiliate, setIsAffiliate] = useState(false);

  const loginStatus = userCtx.user ? true : false;
  const [isLogin, setIsLogin] = useState(loginStatus);

  useEffect(() => {
    setIsLogin(!!userCtx.user);
  }, [userCtx.user]);

  const getAccount = useCallback(async () => {
    try {
      const api_url = process.env.API_URL;

      const response = await fetch(`${api_url}api/users/account`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      const res = await response.json();

      if (!response.ok) {
        return;
      }
      setIsAffiliate(res.data.affiliate.affiliateData);
    } catch (error) {}
  }, [userCtx]);

  useEffect(() => {
    const user = userCtx.user;
    if (user) {
      getAccount();
    }
  }, [userCtx.user, getAccount]);

  return { isAffiliate, isLogin };
};
