export const menuListMobile = [
  {
    title_menu: "What's New",
    url_path: "/whats-new",
    menu: [],
  },
  {
    title_menu: "Shop",
    url_path: "#",
    menu: [
      {
        name: "Categories",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Jewelry Set",
            url_path: "/bundle/jewelry-set",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Fragrance",
            url_path: "/product-category/fragrance",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Ring",
            url_path: "/product-category/ring",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Earrings",
            url_path: "/product-category/earrings",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Bracelet",
            url_path: "/product-category/bracelet",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Necklace",
            url_path: "/product-category/necklace",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Pendant",
            url_path: "/product-category/pendant",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Charms",
            url_path: "/product-category/charms",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Anklet",
            url_path: "/product-category/anklet",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Branklet",
            url_path: "/product-category/branklet",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Wedding",
            url_path: "/product-category/wedding",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Engagement",
            url_path: "/product-category/engagement",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "All Jewelries",
            url_path: "/all-jewelries",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },
      {
        name: "Collection",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Personal",
            url_path: "/collection/personal",
            is_sub_title: false,
            sub_menu: [],
            class: "new",
          },
          {
            name: "Raya & Eid",
            url_path: "/collection/raya-and-eid",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Wedding & Diamond",
            url_path: "/collection/wedding",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Aurum SIX",
            url_path: "/collection/six",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Birthstone",
            url_path: "/collection/birthstone",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Charms",
            url_path: "/collection/charms",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Emily in Paris",
            url_path: "/collection/emily-in-paris",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Voyage (Aurum Lab x Andrea Dian)",
            url_path: "/collection/voyage",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Aurum Lab Fragrance",
            url_path: "/fragrance",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "All Collection",
            url_path: "/collection",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },

      {
        name: "Materials",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "6K Rose Gold",
            url_path: "/product-tag/6k rose gold",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "9K Yellow Gold",
            url_path: "/product-tag/9k yellow gold",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "9K White Gold",
            url_path: "/product-tag/9k white gold",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "9K Rose Gold",
            url_path: "/product-tag/9k rose gold",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Diamond Stone",
            url_path: "/product-tag/diamond stone",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Zirconia Stone",
            url_path: "/product-tag/zirconia stone",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Pearls",
            url_path: "/product-tag/pearls",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Mother of Pearl",
            url_path: "/product-tag/mother of pearl",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },
    ],
  },
  {
    title_menu: "Gift",
    url_path: "#",
    menu: [
      {
        name: "Gifts",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          // {
          //   name: "Gift Guide",
          //   url_path: "/gift-guide",
          //   is_sub_title: false,
          //   is_blank: true,
          //   sub_menu: [],
          // },
          {
            name: "Gift Card",
            url_path: "/gift-card",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Animated Gift Card",
            url_path: "/animated-gift-card",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Gift Box",
            url_path: "/gift-box",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },

      {
        name: "Gifts by Price/Budget?",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Gifts under 1 mio",
            url_path: "/product-category/gift-by-price/gift-under-1-mio/",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Gifts 1 - 2 mio",
            url_path: "/product-category/gift-by-price/gift-1-2-mio/",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Gifts 2 - 3 mio",
            url_path: "/product-category/gift-by-price/gift-2-3-mio/",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Gifts over 3 mio",
            url_path: "/product-category/gift-by-price/gift-over-3-mio/",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },
    ],
  },
  {
    title_menu: "Who We Are",
    url_path: "#",
    menu: [
      {
        name: "",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "The Brand",
            url_path: "/the-brand",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Magazine",
            url_path: "/magazine",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Catalog",
            url_path: "/magazine-catalog",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Monthly Issue",
            url_path: "/monthly-issue",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },
    ],
  },
  {
    title_menu: "Our Store",
    url_path: "#",
    menu: [
      {
        name: "Offline Store",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "Location",
            url_path: "/our-store",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Catalog",
            url_path: "/store-catalog",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },
    ],
  },
  {
    title_menu: "Info",
    url_path: "#",
    menu: [
      {
        name: "",
        url_path: "#",
        is_sub_title: true,
        sub_menu: [
          {
            name: "FAQs",
            url_path: "/faqs",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "How To Order",
            url_path: "/how-to-order",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Terms & Condition",
            url_path: "/term-condition",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Ring Size Guide",
            url_path: "/ring-size-guide",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Tracking your order",
            url_path: "/track-order",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Contact Us",
            url_path: "/contact-us",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Partnership",
            url_path: "/partnership",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Affiliate Program",
            url_path: "/affiliate",
            is_sub_title: false,
            sub_menu: [],
          },
          {
            name: "Career",
            is_blank: true,
            url_path: "https://careers.theaurumlab.com/",
            is_sub_title: false,
            sub_menu: [],
          },
        ],
      },
    ],
  },
];
