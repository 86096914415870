import { useRef, useState, useEffect, Fragment } from "react";
import styles from "./menu-navigation-2.module.css";
import Link from "next/link";
import Image from "next/image";
const MenuNavigation2 = ({ title_menu, listMenu, dark }) => {
  const isDark = dark ?? false;
  const [hoverImage, setHoverImage] = useState();
  return (
    <>
      <div className={`${styles.menu_canvas} ${isDark ? styles.dark : ""}`}>
        <hr className={styles.hr} />
        <div
          className={` ${
            title_menu == "Shop" ? styles.main_menu_shop : styles.main_menu
          }`}
        >
          <div className={styles.listMenu}>
            {listMenu.map((list, key) => (
              <Fragment key={key}>
                <div className={styles.sub_menu}>
                  {list.is_sub_title && (
                    <div className={styles.sub_title}>{list.name}</div>
                  )}
                  {list.sub_menu.map((sub, key) => (
                    <Fragment key={key}>
                      <Link
                        className={isDark ? "dark" : ""}
                        href={sub.url_path}
                        target={sub.is_blank && "_blank"}
                        onMouseEnter={() => setHoverImage(sub.image)}
                        onMouseLeave={() => setHoverImage(null)}
                      >
                        <div className={styles.menu}>
                          {sub.name}
                          {sub.class && (
                            <div className={styles.new}>{sub.class}</div>
                          )}
                        </div>
                      </Link>
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            ))}
          </div>
          <div className={styles.menuImage}>
            {hoverImage && (
              <Image
                src={hoverImage}
                alt={hoverImage}
                width={1080}
                height={720}
                quality={60}
                className={styles.fineImage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuNavigation2;
